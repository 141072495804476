<template>
	<div class="menu-button" :class="{disabled, outline}">
		<div class="menu-button-text">{{ title }}</div>
		<div class="logos-carousel" v-if="logosCarousel">
			<VerticalCarousel :images="logosCarousel" :autoplay="3000" infinite-scroll />
		</div>
	</div>
</template>

<script>
import {Pagination, Slide} from "vue3-carousel";
import VerticalCarousel from "@/new-menu/components/generic/VerticalCarousel.vue";

export default {
	name: "MenuButton",
	components: {VerticalCarousel, Pagination, Slide},
	props: {
		title: {
			type: String,
			required: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		outline: {
			type: Boolean,
			default: false
		},
		logosCarousel: {
			type: Array,
			default: null
		}
	},
	data() {
		return {
			currentSlide: 0
		}
	},
}
</script>

<style scoped lang="scss">
.menu-button {
	min-width: 100px;
	max-width: 100%;
	width: auto;
	padding: 10px var(--padding-horizontal);
	background: var(--business-color);
	border-radius: var(--rounded-corner-s);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	box-shadow: var(--theme-box-shadow);
	transition: box-shadow 0.2s ease-in-out, opacity 0.2s ease-in-out;

	&:hover {
		box-shadow: var(--theme-box-shadow-light);
	}

	&:active {
		box-shadow: var(--theme-box-shadow-null);
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	.menu-button-text {
		font-size: var(--font-size-l);
		line-height: var(--line-height-l);
		color: var(--business-text-color);
		flex-grow: 1;
		text-align: center;
	}

	.logos-carousel {
		margin-left: var(--padding-horizontal);
		border-radius: var(--rounded-corner-s);
		background: white;
		box-shadow: inset 4px 4px 10px -3px rgba(0,0,0,0.4);

	}

	&.outline {
		background: var(--theme-bg2-color);
		border: 2px solid var(--business-color);

		.menu-button-text {
			color: var(--business-color);
		}

		.logos-carousel {
			border: 2px solid var(--business-color);
			margin-right: -2px;
		}
	}
}
</style>