<template>
	<div class="pay-table-actions">
		<div class="cta-quick-actions">
			<MenuButton :title="actionsStore.primaryText" :logos-carousel="actionsStore.primaryLogos" @click="payPrimary" />
		</div>
		<div class="cta-other-actions" v-if="actionsStore.otherPaymentMethods.length > 0">
			<MenuButton outline :title="getString('other_methods')" :logos-carousel="actionsStore.logosOtherPayBill" @click="otherPaymentsActive = true" v-if="actionsStore.otherPaymentMethods.length > 0" />
		</div>
		<AppBottomSheet id="other-payments-bottom-sheet" :forceWidth="bottomSheetWidth" :active="otherPaymentsActive" @toggling-popup="togglingOtherPayments">
			<OrderOtherCtas
				v-if="otherPaymentsActive"
				@checkout="payOtherMethods"
				:voucherly="actionsStore.otherPaymentMethods.includes('voucherly')"
				:satispay="actionsStore.otherPaymentMethods.includes('satispay')"
				:klarna="actionsStore.otherPaymentMethods.includes('klarna')"
				:btc="actionsStore.otherPaymentMethods.includes('btc')"
			/>
		</AppBottomSheet>
		<AppBottomSheet id="stripe-bottom-sheet" :forceWidth="bottomSheetWidth" :active="stripeActive" @toggling-popup="togglingStripe">
			<StripeCheckout v-if="menusStore.paymentMethodsAvailable.includes('stripe')" :client-secret="clientSecret" :order-key="orderKey" :redirect-url="redirectUrl" :payment-methods="paymentMethods" :amount="amount" @remove-pm="removePm" />
		</AppBottomSheet>
		<AppBottomSheet id="satispay-bottom-sheet" :forceWidth="bottomSheetWidth" :active="satispayActive" @toggling-popup="togglingSatispay">
			<div class="container-checkout-satispay-app" v-if="satispayActive">
				<div class="container-button container-button-cta container-button-cta-satispay">
					<a :href="redirectUrl">
						<div class="input-button translate">{{ getString("pay_with")+" " }}<b>{{ getString("satispay") }}</b></div>
					</a>
				</div>
			</div>
		</AppBottomSheet>
		<div class="ctas-loader" :class="{hidden: !loading}">
			<AppLoader :color="'#'+menusStore.business.color" v-if="loading" />
		</div>
	</div>
</template>

<script>
import MenuButton from "@/new-menu/components/generic/MenuButton.vue";
import {useActionsStore, useCartStore, useEvsStore, useMenusStore} from "@/new-menu/stores";
import {useLanguageStore, useNotificationStore, useScriptStore} from "@/shared/stores";
import AppBottomSheet from "@/shared/components/AppBottomSheet.vue";
import OrderOtherCtas from "@/new-menu/components/order/OrderOtherCtas.vue";
import WindowWidth from "@/console-new/mixins/WindowWidth";
import StripeCheckout from "@/new-menu/components/order/StripeCheckout.vue";
import AppLoader from "@/shared/components/AppLoader.vue";

export default {
	setup() {
		let actionsStore = useActionsStore();
		let languageStore = useLanguageStore();
		const notificationStore = useNotificationStore();
		const menusStore = useMenusStore();
		const evsStore = useEvsStore();
		const scriptStore = useScriptStore();
		const cartStore = useCartStore();
		return {actionsStore, languageStore, getString: languageStore.getString, notificationStore, menusStore, evsStore, scriptStore, cartStore}
	},
	name: "PayTableActions",
	components: {AppLoader, StripeCheckout, OrderOtherCtas, AppBottomSheet, MenuButton},
	mixins: [WindowWidth],
	props: {
		amount: {
			type: Number,
			required: true
		},
		paymentType: {
			type: Number,
			default: 0
		},
		itemsRedeemed: {
			type: Array,
			default: null
		}
	},
	data() {
		return {
			otherPaymentsActive: false,
			loading: false,
			stripeActive: false,
			satispayActive: false,
			clientSecret: null,
			orderKey: null,
			redirectUrl: null,
			paymentMethods: [],
		}
	},
	computed: {
		bottomSheetWidth() {
			if(this.windowWidth < 550) {
				return "100%";
			} else {
				return "550px";
			}
		},
	},
	methods: {
		togglingOtherPayments(active) {
			if(!active) {
				this.otherPaymentsActive = active;
			}
		},
		togglingStripe(active) {
			if(!active) {
				this.stripeActive = active;
			}
		},
		togglingSatispay(active) {
			if(!active) {
				this.satispayActive = active;
			}
		},
		removePm(v) {
			this.paymentMethods = this.paymentMethods.filter(pm => pm.id !== v);
		},
		payPrimary() {

			if(this.amount > 0) {

				if(this.actionsStore.primaryPaymentMethod === "stripe") {
					this.payStripe();
				} else if(this.actionsStore.primaryPaymentMethod === "klarna") {
					this.payOtherMethods("klarna");
				} else if(this.actionsStore.primaryPaymentMethod === "satispay") {
					this.payOtherMethods("satispay");
				} else {
					this.payStripe();
				}

			} else {
				this.notificationStore.showNotification("cannot_be_zero");
			}
		},
		payStripe() {

			this.evsStore.enqueueEvent('click', 'pay-bill-stripe');

			if(!this.scriptStore.loadedScripts.includes("stripe")) {
				this.notificationStore.showNotification("loading_stripe");
				this.scriptStore.loadScript("stripe", () => {});
				return;
			}

			this.loading = true;
			this.actionsStore.payBill("stripe", this.amount, this.paymentType, this.itemsRedeemed, (res) => {
				this.loading = false;
				if(res && res.payment_intent && res.payment_intent.client_secret && res.payment_intent.amount) {
					if(res.customer_email && res.customer_email !== this.cartStore.customerEmail) {
						this.cartStore.customerEmail = res.customer_email;
						this.menusStore.saveData();
					}
					this.clientSecret = res.payment_intent.client_secret;
					this.redirectUrl = res.order_url;
					this.orderKey = "p"+res.order_key;
					this.orderAmount = res.payment_intent.amount;
					this.paymentMethods = res.payment_methods ? res.payment_methods : [];
					this.stripeActive = true;
				}
			});

		},
		payOtherMethods(method) {
			if(method === "satispay" || method === "klarna") {
				this.cartStore.requireEmail(() => {
					this.payOtherMethodsPostAction(method);
				});
			} else {
				this.payOtherMethodsPostAction(method);
			}
		},
		payOtherMethodsPostAction(method) {
			this.evsStore.enqueueEvent('click', 'pay-bill-' + method);

			this.loading = true;
			this.otherPaymentsActive = false;
			this.actionsStore.payBill(method, this.amount, this.paymentType, this.itemsRedeemed, (res) => {
				if(!res) {
					this.loading = false;
					return;
				}
				if(method === "klarna") {
					if(res.payment_intent && res.payment_intent.client_secret && res.order_url) {
						if(res.customer_email && res.customer_email !== this.cartStore.customerEmail) {
							this.cartStore.customerEmail = res.customer_email;
							this.menusStore.saveData();
						}
						this.clientSecret = res.payment_intent.client_secret;
						this.redirectUrl = res.order_url;
						if(this.scriptStore.loadedScripts.includes("stripe")) {
							this.confirmKlarna();
						} else {
							this.scriptStore.loadScript("stripe", () => {
								this.confirmKlarna();
							});
						}
					} else {
						this.notificationStore.showNotification("generic_fail");
						this.loading = false;
					}
				} else if(method === "satispay") {
					if(res.invoice_url) {
						this.redirectUrl = res.invoice_url;
						this.satispayActive = true;
						this.loading = false;
					} else {
						this.notificationStore.showNotification("generic_fail");
						this.loading = false;
					}
				} else if(method === "voucherly") {
					if(res.checkoutUrl) {
						window.location.href = res.checkoutUrl;
					} else {
						this.notificationStore.showNotification("generic_fail");
						this.loading = false;
					}
				} else {
					this.notificationStore.showNotification("generic_fail");
					this.loading = false;
				}
			});
		},
		confirmKlarna() {

			let stripe = Stripe(STRIPE_PK);
			stripe.confirmKlarnaPayment(
				this.clientSecret,
				{
					payment_method: {
						billing_details: {
							email: this.cartStore.customerEmail,
							address: {
								country: COUNTRY,
							},
						},
					},
					receipt_email: this.cartStore.customerEmail,
					return_url: this.redirectUrl,
				}
			).then((error) => {
				if (error) {
					this.loading = false;
					this.notificationStore.showNotification("generic_fail");
				}
			});

		},
	},
	mounted() {
		let scripts = ["captcha"];
		if(this.menusStore.paymentMethodsAvailable.includes("stripe")) {
			scripts.push("stripe");
		}
		this.scriptStore.loadScriptsParallel(scripts, () => {

		});

	}
}
</script>

<style lang="scss">
#other-payments-bottom-sheet, #stripe-bottom-sheet, #satispay-bottom-sheet {
	background-color: var(--theme-txt1-alpha60-color);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;

	&.hidden {
		opacity: 0;
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px);
		background-color: var(--theme-txt1-alpha60-color);
	}

	.bottom-sheet {
		padding: 0;
		background-color: var(--theme-bg2-color);
		border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		max-height: calc(95% - 15px);

		.bottom-sheet-measurer {
			border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		}
	}
}
</style>
<style scoped lang="scss">
.pay-table-actions {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 8px;
	position: relative;

	.cta-quick-actions {
		display: flex;
		width: 100%;
		gap: 8px;

		.menu-button {
			flex-grow: 1;
			padding: 8px var(--padding-horizontal);
			min-height: 44px;

		}

	}

	.cta-other-actions {
		display: flex;
		width: 100%;
		gap: 8px;

		.menu-button {
			flex-grow: 1;
			padding: 6px var(--padding-horizontal);
			min-height: 44px;

			&.button-cash {
				width: 70px;
			}
		}
	}

	.ctas-loader {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--theme-txt1-alpha60-color);
		backdrop-filter: blur(6px);
		-webkit-backdrop-filter: blur(6px);
		transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;
		visibility: visible;
		opacity: 1;
		border-radius: calc(var(--rounded-corner) - 3px);

		&.hidden {
			opacity: 0;
			backdrop-filter: blur(0px);
			-webkit-backdrop-filter: blur(0px);
			visibility: hidden;
		}
	}

}
.container-checkout-satispay-app {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100px;
	overflow: hidden;
	border-top-left-radius: var(--rounded-corner);
	border-top-right-radius: var(--rounded-corner);
	width: 100%;

	.container-button-cta-satispay {
		margin: auto;

		.input-button {
			text-transform: none;
			opacity: 1;
			background-color: rgb(249, 76, 67) !important;
			border: 1px solid rgb(249, 76, 67);
			line-height: 1.5;
			border-radius: var(--rounded-corner);
			transition-delay: 0s;
			transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
			text-decoration: none;
			width: fit-content;
			font-size: var(--font-size-l);
			color: rgba(255, 255, 255, 0.8);
			padding: 12px 12px 12px 50px;
			position: relative;

			b {
				color: white;
			}

			&::after {
				content: '';
				height: 33px;
				width: 33px;
				border-radius: var(--rounded-corner);
				background-image: url("https://img.qromo.io/img/icon-satispay-square.svg");
				background-position: center;
				background-repeat: no-repeat;
				display: block;
				position: absolute;
				transform: translateY(-50%);
				top: 50%;
				left: 9px;
			}

			&:hover {
				transform: none;
				box-shadow: rgb(252 170 166) 0px 0.1rem 0.3rem 0.1rem;
			}
		}
	}

}
</style>