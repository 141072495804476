<template>
	<div class="menu-quantity">
		<div class="label" v-if="label">{{ label }}</div>
		<div class="quantity">
			<div class="quantity-button" @click="decrement" v-html="svgStore.get('iconMinus')"></div>
			<div class="quantity-value">{{ currentValue }}</div>
			<div class="quantity-button" @click="increment" v-html="svgStore.get('iconPlus')"></div>
		</div>
	</div>
</template>

<script>
import {useSvgStore} from "@/shared/stores";

export default {
	setup() {
		const svgStore = useSvgStore();
		return {svgStore}
	},
	name: "MenuQuantity",
	props: {
		value: {
			type: Number,
			default: 1
		},
		min: {
			type: Number,
			default: null
		},
		max: {
			type: Number,
			default: null
		},
		label: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			currentValue: this.value
		}
	},
	emits: ['changed-value'],
	methods: {
		increment() {
			if(this.max === null || this.currentValue < this.max) {
				this.currentValue++;
				this.$emit('changed-value', this.currentValue);
			}
		},
		decrement() {
			if(this.min === null || this.currentValue > this.min) {
				this.currentValue--;
				this.$emit('changed-value', this.currentValue);
			}
		}
	},
	watch: {
		value(newValue) {
			this.currentValue = newValue;
		},
		max(newValue) {
			if(this.currentValue > newValue) {
				this.currentValue = newValue;
				this.$emit('changed-value', this.currentValue);
			}
		},
		min(newValue) {
			if(this.currentValue < newValue) {
				this.currentValue = newValue;
				this.$emit('changed-value', this.currentValue);
			}
		}
	}
}
</script>

<style scoped lang="scss">
.menu-quantity {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.label {
		font-size: var(--font-size);
		line-height: var(--line-height);
		color: var(--theme-txt2-color);
		margin-bottom: 8px;
	}

	.quantity {
		display: flex;
		align-items: stretch;
		justify-content: center;
		gap: var(--padding-horizontal-s);

		.quantity-button {
			cursor: pointer;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--business-color);
			border-radius: var(--rounded-corner);

			&:deep(svg) {
				width: 16px;
				height: 16px;

				.fill {
					fill: var(--business-text-color);
				}
				.stroke {
					stroke: var(--business-text-color);
				}
			}

		}

		.quantity-value {
			width: 70px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--theme-bg2-color);
			color: var(--theme-txt1-color);
			border-radius: var(--rounded-corner);
			border: 1px solid var(--business-color);
		}

	}
}
</style>