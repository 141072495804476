<template>
	<div class="pay-table-partial-container">
		<div class="section-title">{{ amountType === 'total' ? getString('total_amount') : getString('choose_amount') }}</div>
		<div class="section-inset section-amount" :class="{bigPadding: amountType === 'total'}">
			<div class="total-amount" v-if="amountType === 'total'">{{ readableEurValue(intentToPay/100) }}</div>
			<div class="sections-partial" v-else>
				<div class="section-inset-highlight">
					<div class="total-amount-edit">
						<MenuTextInput type="number" :value="intentToPay > 0 ? (intentToPay/100)+'' : ''" @changed-text="changedIntentToPay" />
					</div>
				</div>
				<div class="amounts-rows">
					<div class="row-amount">
						<div class="amount-info">{{ getString('total_bill') }}</div>
						<div class="amount-value">{{ readableEurValue(actionsStore.payBillAmount/100) }}</div>
					</div>
					<div class="row-amount">
						<div class="amount-info">{{ getString('left_to_pay') }}</div>
						<div class="amount-value">{{ readableEurValue((actionsStore.payBillAmount - intentToPay)/100) }}</div>
					</div>
					<div class="row-amount row-primary">
						<div class="amount-info">{{ getString('amount_to_pay') }}</div>
						<div class="amount-value">{{ readableEurValue(intentToPay/100) }}</div>
					</div>
				</div>
			</div>
		</div>
		<PayTableActions :amount="intentToPay" :payment-type="paymentType" />
	</div>
</template>

<script>
import {useActionsStore, useCartStore, useMenusStore} from "@/new-menu/stores";
import {useLanguageStore} from "@/shared/stores";
import MenuTextInput from "@/new-menu/components/generic/MenuTextInput.vue";
import PayTableActions from "@/new-menu/components/pay/PayTableActions.vue";

export default {
	components: {MenuTextInput, PayTableActions},
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		let actionsStore = useActionsStore();
		return {cartStore, menusStore, getString: languageStore.getString, readableEurValue: languageStore.readableEurValue, actionsStore}
	},
	name: "PayTablePartial",
	props: {
		amountType: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			partialAmount: this.actionsStore.payBillAmount,
		}
	},
	computed: {
		intentToPay() {
			if(this.amountType === 'total') {
				return this.actionsStore.payBillAmount;
			} else {
				return this.partialAmount;
			}
		},
		paymentType() {
			if(this.amountType === 'total') {
				return 0;
			} else {
				return 1;
			}
		}
	},
	methods: {
		changedIntentToPay(value) {
			if(value === '') {
				this.partialAmount = 0;
			} else {
				let amount = parseFloat(value) * 100;
				if(amount > this.actionsStore.payBillAmount) {
					amount = this.actionsStore.payBillAmount;
				} else if(amount < 0) {
					amount = 0;
				}
				this.partialAmount = Math.round(amount);
			}
		}
	},
	watch: {
		'actionsStore.payBillAmount': function() {
			if(this.actionsStore.payBillAmount < this.partialAmount) {
				this.partialAmount = this.actionsStore.payBillAmount;
			}
		}
	}
}
</script>

<style scoped lang="scss">
.pay-table-partial-container {
	padding: var(--padding-vertical) var(--padding-horizontal);

	.section-title {
		margin-bottom: var(--padding-vertical);
		font-size: var(--font-size-l);
		line-height: var(--line-height-l);
		color: var(--theme-txt1-color);
		font-weight: 700;

	}

	.section-inset {
		border-radius: var(--rounded-corner);
		box-shadow: inset var(--theme-box-shadow-xlight);
		background-color: var(--theme-bg1-color);
		padding: var(--padding-vertical) var(--padding-horizontal);

		.section-inset-highlight {
			width: 100%;
			border-radius: var(--rounded-corner);
			background-color: var(--theme-bg2-color);
			border: 1px solid var(--business-color);
			padding: var(--padding-vertical) var(--padding-horizontal-s);
			.total-amount-edit {
				&:deep(input) {
					width: 100%;
					height: unset;
					border: none;
					border-radius: 0;
					font-size: var(--font-size-xxxl);
					line-height: var(--line-height-xxxl);
					padding: 0;
				}
			}
		}

		.amounts-rows {
			width: 100%;
			gap: 4px;
			display: flex;
			flex-direction: column;
			margin-top: var(--padding-vertical);

			.row-amount {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 8px;

				.amount-info {
					font-size: var(--font-size-s);
					line-height: var(--line-height-s);
					color: var(--theme-txt2-color);
				}

				.amount-value {
					font-size: var(--font-size-s);
					line-height: var(--line-height-s);
					color: var(--theme-txt2-color);
					font-weight: 500;
				}

				&.row-primary {
					.amount-info {
						font-size: var(--font-size-m);
						line-height: var(--line-height-m);
						color: var(--theme-txt1-color);
					}
					.amount-value {
						font-size: var(--font-size-m);
						line-height: var(--line-height-m);
						color: var(--theme-txt1-color);
						font-weight: 700;
					}
				}
			}
		}


	}

	.section-amount {
		margin-bottom: var(--padding-vertical);

		.total-amount {
			font-size: var(--font-size-xxxl);
			line-height: var(--line-height-xxxl);
			color: var(--theme-txt1-color);
			font-weight: 700;
			font-style: normal;
			@include truncateString(1);
		}

		&.bigPadding {
			padding: 20px var(--padding-horizontal);
		}
	}
}
</style>