<template>
	<div class="carousel-container-components">
		<div class="carousel-components-wrapper">
			<div class="carousel-components" ref="carousel-components">

				<div class="carousel-component" v-for="(image, index) in images" :key="index">
					<img :src="image" alt="icon">
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import WindowWidth from "@/console-new/mixins/WindowWidth";

export default {
	name: "VerticalCarousel",
	data() {
		return {
			currentSlide: 0,
			numSlides: this.images.length,
			isTransitioning: false,
		}
	},
	mixins: [WindowWidth],
	props: {
		autoplay: {
			type: Number,
			default: null
		},
		infiniteScroll: {
			type: Boolean,
			default: false
		},
		transitionTime: {
			type: Number,
			default: 500
		},
		images: {
			type: Array,
			required: true
		},
	},
	methods: {
		goNextSlide() {
			if(!this.isTransitioning) {
				this.isTransitioning = true;
				if(this.infiniteScroll && this.numSlides > 2 && this.currentSlide === this.numSlides - 2) {
					let carousel = this.$refs['carousel-components'];
					if(!carousel) {
						return;
					}

					// Move the first component to the end
					let firstComponent = carousel.children[0];
					carousel.appendChild(firstComponent);

					// Change the current slide to remain in the same position without transition
					this.currentSlide -= 1;
					let originalTransition = carousel.style.transition;
					carousel.style.transition = "none";
					carousel.style.setProperty("--current-slide", this.currentSlide);

					// Wait for the next frame and then goNextSlide with transition
					let self = this;
					requestAnimationFrame(() => {
						requestAnimationFrame(() => {
							carousel.style.transition = originalTransition;
							this.currentSlide = this.mod(this.currentSlide + 1, this.numSlides);
							this.changeSlide(this.currentSlide);
						});
					});

				} else {
					this.currentSlide = this.mod(this.currentSlide + 1, this.numSlides);
					this.changeSlide(this.currentSlide);
				}
			}
		},
		goPreviousSlide() {
			if(!this.isTransitioning) {
				this.isTransitioning = true;
				if(this.infiniteScroll && this.numSlides > 2 && this.currentSlide === 0) {
					let carousel = this.$refs['carousel-components'];
					if(!carousel) {
						return;
					}

					// Move the last component to the beginning
					let lastComponent = carousel.children[carousel.children.length - 1];
					carousel.insertBefore(lastComponent, carousel.children[0]);

					// Change the current slide to remain in the same position without transition
					this.currentSlide += 1;
					let originalTransition = carousel.style.transition;
					carousel.style.transition = "none";
					carousel.style.setProperty("--current-slide", this.currentSlide);

					// Wait for the next frame and then goPreviousSlide with transition
					requestAnimationFrame(() => {
						requestAnimationFrame(() => {
							carousel.style.transition = originalTransition;
							this.currentSlide = this.mod(this.currentSlide - 1, this.numSlides);
							this.changeSlide(this.currentSlide);
						});
					});

				} else {
					this.currentSlide = this.mod(this.currentSlide - 1, this.numSlides);
					this.changeSlide(this.currentSlide);
				}
			}
		},
		changeSlide(t) {
			let carousel = this.$refs['carousel-components'];
			if(!carousel) {
				return;
			}
			carousel.style.setProperty("--current-slide", t);
			setTimeout(() => {
				this.isTransitioning = false;
			}, this.transitionTime);
		},
		mod(e, t) {
			let n = e % t;
			return n < 0 ? n + t : n;
		},
	},
	mounted() {
		if(this.autoplay) {
			setInterval(() => {
				this.goNextSlide();
			}, this.autoplay);
		}
	}
}
</script>

<style scoped lang="scss">

.carousel-container-components {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	--current-slide: 0;
	--carousel-height: 28px;
	height: var(--carousel-height);

	.carousel-components-wrapper {
		width: 100%;
		overflow: hidden;
		position: relative;

		.carousel-components {
			width: 100%;
			height: 100%;
			display: flex;
			transition: transform 0.5s;
			transform: translateY(calc(-100% * var(--current-slide)));
			flex-direction: column;

			.carousel-component {
				flex: 0 0 100%;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					padding: 2px 6px;
					width: 44px;
					height: var(--carousel-height);
					object-fit: contain;
				}
			}

		}
	}
}
</style>